/** @jsx jsx */
import { memo, ReactElement, useEffect, useState } from "react";
import { jsx } from "theme-ui";
import { StringParam, useQueryParams } from "use-query-params";

import ErrorView from "../components/ErrorView";
import MasterLayout from "../components/MasterLayout";
import { logout } from "../http/endpoints";
import { urlWithQuery } from "../util/queryParams";

const LogoutPage = memo(function LogoutPage(): ReactElement {
  const [errorMessage, setErrorMessage] = useState<string>();
  const [{ redirectUrl, immediate }] = useQueryParams({
    redirectUrl: StringParam,
    inviteToken: StringParam,
    initialEmail: StringParam,
    immediate: StringParam,
  });

  useEffect(() => {
    (async () => {
      try {
        await logout();
        // Immediately redirect to the redirectUrl if `immediate=true`.
        if (immediate === "true" && redirectUrl) {
          window.location.href = redirectUrl;
        } else {
          window.location.replace(urlWithQuery("/", { redirectUrl }));
        }
      } catch (error) {
        setErrorMessage(
          (error as Error).message ?? "An unknown error occurred.",
        );
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Legacy (turned on exhaustive-deps for new code)
  }, []);

  return (
    <MasterLayout title="Logout">
      {errorMessage && (
        <ErrorView title="Failed to log out" message={errorMessage} />
      )}
    </MasterLayout>
  );
});
export default LogoutPage;
